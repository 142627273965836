import React from "react";
import { ReactComponent as IconInstagram } from "./assets/icons/instagram.svg";
import { ReactComponent as IconTwitter } from "./assets/icons/twitter.svg";
import { ReactComponent as IconFacebook } from "./assets/icons/facebook.svg";
import "./App.css";

class App extends React.Component {
  constructor() {
    super();
    this.state = { email: "", submitted: false, error: false };
  }

  handleInputChange = (event) => {
    this.setState({ email: event.target.value });
  };

  handleSubmit = (event) => {
    event.preventDefault();
    console.log("Email submitted:", this.state.email);

    // Assuming the email submission was successful
    this.setState({ email: "", submitted: true });

    // Optionally, you could reset the submission message after a delay
    setTimeout(() => {
      this.setState({ submitted: false });
    }, 5000); // Clears the message after 5 seconds
  };

  render = () => {
    return (
      <div className="card">
        <div className="header">
          <div className="logo">
            <a href=".">MIRA</a>
          </div>
        </div>
        <div className="content">
          <div className="title-holder">
            <h1>Launching Soon</h1>
            <p className="tagline">Be the first to know!</p>
            {/* Added Join the Waitlist button */}
            <a
              href="https://www.mirabeauty.co/lists/?p=subscribe"
              className="cta join-waitlist"
            >
              Join the waitlist
            </a>
          </div>
          <div className="social">
            <a
              href="https://www.instagram.com/mira.beautyco/"
              title="Instagram"
              target="_blank"
              rel="noopener noreferrer"
            >
              <IconInstagram className="icon" />
            </a>
            <a
              href="https://x.com/mirabeautyco"
              title="X/Twitter"
              target="_blank"
              rel="noopener noreferrer"
            >
              <IconTwitter className="icon" />
            </a>
            <a
              href="https://facebook.com/mirabeautyandstyling"
              title="X/Twitter"
              target="_blank"
              rel="noopener noreferrer"
            >
              <IconFacebook className="icon" />
            </a>
          </div>
        </div>
        <div className="footer">
          <span>© 2024 MIRA Beauty LLC</span>
        </div>
      </div>
    );
  };
}

export default App;

/*           {!this.state.submitted ? (
            <form onSubmit={this.handleSubmit} className="notify-form">
              <div className="input-group">
                <input
                  type="email"
                  placeholder="Enter your email"
                  value={this.state.email}
                  onChange={this.handleInputChange}
                  required
                  className="email-input"
                />
                <button type="submit" className="cta">
                  →
                </button>
              </div>
              <p className="explanation">
                We’ll keep you updated as we get closer to launch!
              </p>
            </form>
          ) : (
            <p className="thank-you">
              You're in! Stay tuned for exciting updates!
            </p>
          )}
*/
